export default {
	items: [
		{
			name: 'dashboard',
			url: '/home',
			icon: 'fa fa-columns',
		},
		{
			name: 'go_to_store',
			url: '/store',
			icon: 'fas fa-shopping-cart',
		},
		{
			name: 'go_to_physical_store',
			url: '/pickup/store',
			icon: 'fas fa-shopping-bag',
		},
		{
			name: 'upgrade',
			url: '/upgrade',
			icon: 'fas fa-box',
		},
		// {
		// 	name: 'customCarts',
		// 	url: '/personalized-carts',
		// 	icon: 'fas fa-cart-plus',
		// },
		{
			name: 'customPrices',
			url: '/custom-prices',
			icon: 'fas fa-dollar-sign',
			// badge: {
			// 	variant: 'green',
			// 	text: 'new_feature',
			// },
		},
		{
			name: 'giftOrders',
			url: '/gift-orders',
			icon: 'fas fa-gift',
		},
		{
			name: 'orders',
			url: '/orders/',
			icon: 'fa fa-list-alt',
			children: [
				{
					name: 'orderHistory',
					url: '/orders/all',
					icon: 'fa fa-bars',
				},
				{
					name: 'referredOrders',
					url: '/orders/referred',
					icon: 'fas fa-bars',
				},
				{
					name: 'pickupOrders',
					url: '/orders/pickup',
					icon: 'fas fa-shopping-bag',
				},
				{
					name: 'downloads',
					url: '/orders/downloads',
					icon: 'fas fa-download',
				},
				{
					name: 'eventTickets',
					url: '/orders/event-tickets',
					icon: 'fas fa-calendar-check',
				},
				{
					name: 'videos',
					url: '/orders/videos',
					icon: 'fas fa-video',
				},
			],
		},
		// {
		// 	name: 'productReviews',
		// 	url: '/product-reviews',
		// 	icon: 'fas fa-comment-alt',
		// },
		{
			name: 'coupons',
			url: '/coupons',
			icon: 'fas fa-ticket-alt',
		},
		{
			name: 'myNetwork',
			url: '/my-network/',
			icon: 'fa fa-share-alt',
			children: [
				{
					name: 'binaryTree',
					url: '/my-network/binary-tree',
					icon: 'fas fa-network-wired',
				},
				{
					name: 'unilevelTree',
					url: '/my-network/unilevel-tree',
					icon: 'fas fa-level-down-alt',
				},
				{
					name: 'unilevelSummary',
					url: '/my-network/unilevel-summary',
					icon: 'fas fa-sort-amount-down',
				},
				{
					name: 'distributorOrganization',
					url: '/my-network/distributor-organization',
					icon: 'fa fa-users',
				},
				{
					name: 'sponsoredDistributors',
					url: '/my-network/sponsored-distributors',
					icon: 'fa fa-user',
				},
				{
					name: 'sponsoredCustomers',
					url: '/my-network/sponsored-customers',
					icon: 'fa fa-user-tag',
				},
				{
					name: 'downlinesInfo',
					url: '/my-network/downlines-info',
					icon: 'fa fa-user',
				},
			],
		},
		{
			name: 'wallets',
			url: '/wallets/',
			icon: 'fa fa-wallet',
			// children: [
			// 	{
			// 		name: 'commissionWallet',
			// 		url: '/wallets/transactions/commission',
			// 		icon: 'fa fa-file',
			// 	},
			// 	{
			// 		name: 'depositWallet',
			// 		url: '/wallets/transactions/deposit',
			// 		icon: 'fa fa-file',
			// 	},
			// ],
		},
		{
			name: 'autoshipInformation',
			url: '/autoship-information',
			icon: 'fas fa-truck',
		},
		{
			name: 'reports',
			url: '/reports/',
			icon: 'fa fa-clipboard',
			children: [
				{
					name: 'bv',
					url: '/reports/bv',
					icon: 'fa fa-file',
				},
				{
					name: 'pendingBv',
					url: '/reports/pending-bv',
					icon: 'fa fa-file',
				},
				{
					name: 'commissions',
					url: '/reports/commissions',
					icon: 'fa fa-file',
				},
				{
					name: 'personalBusinessVolume',
					url: '/reports/personal-business-volume',
					icon: 'fa fa-file',
				},
				{
					name: 'autoshipSummary',
					url: '/reports/autoship-summary',
					icon: 'fa fa-file',
				},
				{
					name: 'unilevelRanks',
					url: '/reports/unilevel-ranks',
					icon: 'fa fa-file',
				},
				// {
				// 	name: 'replicatedSiteAnalytics',
				// 	url: '/reports/replicated-site-analytics',
				// 	icon: 'fa fa-file',
				// },
			],
		},
		{
			name: 'mediaLibrary',
			url: '/media-library',
			icon: 'fas fa-newspaper',
		},
		{
			name: 'userAnnouncements',
			url: '/user-announcements',
			icon: 'fas fa-bullhorn',
		},
		{
			name: 'faq',
			url: '/faq',
			icon: 'fas fa-question',
		},
		{
			name: 'support',
			url: '/support',
			icon: 'fas fa-life-ring',
		},
	],
};
